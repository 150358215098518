$color-primary: #46bf8d;

* {
    border: 0;
    margin: 0;
    padding: 0;
}

html {
    background-size: cover;
}

body,
input {
    color: #404040;
    font-family: 'DM Sans', sans-serif;
    font-size: 18px;
}

a,
.link {
    color: #337ab7;
    cursor: pointer;
    text-decoration: none;
}

span.link {
    text-decoration: underline;
}

header {
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 25%);
    height: 70px;
    line-height: 70px;
    position: relative;
    text-align: center;
    z-index: 3;
}

.header__content {
    display: flex;
    margin: 0 auto;
    max-width: 1000px;
    padding: 0 20px;
}

.header__logo {
    img {
        height: 30px;
        vertical-align: middle;
    }
}

.header__links {
    font-size: 0.9rem;
    flex: 1;
    text-align: right;

    a {
        margin-left: 30px;
    }

    .button {
        font-size: 14px;
        font-weight: normal;
        padding: 6px 10px;
    }
}

.form {
    margin: 0 auto;
    max-width: 500px;

    h2 {
        font-size: 1.5rem;
        margin: 25px 0 25px 0;

        :first-child {
            margin-top: 0;
        }
    }

    h3 {
        font-size: 1.2rem;
        margin: 0 0 25px 0;
    }
}

.form--login {
    margin: 30px 0;
}

.fieldset {
    padding: 0 0 20px 0;
}

.button {
    background-color: $color-primary;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 15px 30px 15px 30px;
    transition: 0.3s;

    :hover {
        background-color: #37a97a;
    }

    &:disabled {
        background-color: #f2f2f2;
        border-color: #ccc;
        color: #ccc;
    }
}

.input {
    background-color: #fff;
    border: 1px solid #999;
    border-radius: 5px;
    box-sizing: border-box;
    height: 50px;
    line-height: 50px;
    padding: 0 15px;
    width: 100%;
}

.label {
    display: block;
    font-size: 0.9rem;
    padding: 0 0 5px 0;
    text-transform: uppercase;
}

.label--radio {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    margin: 0 0 20px 0;
    padding: 20px;
    text-transform: none;
    transition: 0.3s;

    :has(input:checked) {
        background: #f1faf6;
        border: 1px solid $color-primary;
    }

    :hover {
        border-color: #47bf8d;
    }

    input {
        margin: 0 5px 0 0;
    }
}

.label--selected {
    background-color: #f0f5f3;
    border-color: #47bf8d;
}

.notice {
    color: #999;
    font-size: 0.8rem;
    line-height: 1.1rem;
    margin: 20px 0 30px 0;
    text-align: justify;
}

.error {
    background: #f7e7e7;
    border-radius: 5px;
    color: #c00;
    font-size: 0.95rem;
    margin: 0 0 30px 0;
    padding: 20px;
}

.prevnext {
    display: flex;
    justify-content: space-between;
}

.amount {
    color: #47bf8d;
    font-size: 4rem;
    line-height: 5rem;
    margin: 0 0 40px 0;
}

.page {
    margin: 0 auto;
    max-width: 1000px;
    padding: 80px 20px;
}

.home {
    text-align: center;

    img {
        max-width: 200px;
        width: 50%;
    }

    h1 {
        font-size: 2.25rem;
        font-weight: normal;
        margin: 40px 0 30px 0;
    }

    p {
        margin: 0 0 20px 0;
    }
}

.align_right {
    text-align: right;
}

.loader {
    text-align: center;
}

.preview {
    h2 {
        margin: 0 0 30px 0;
    }
}

.list {
    line-height: 3rem;
    margin: 0 0 40px 0;
    overflow: auto;

    dt {
        border-bottom: 1px dotted #ccc;
        box-sizing: border-box;
        clear: both;
        float: left;
        padding: 0 0 0 10px;
        text-align: left;
        width: 50%;

        img {
            width: 20px;
        }
    }

    dd {
        border-bottom: 1px dotted #ccc;
        box-sizing: border-box;
        font-weight: bold;
        float: right;
        padding: 0 10px 0 0;
        text-align: right;
        width: 50%;
    }
}

.quote {
    margin: 0 auto;
    max-width: 700px;
    text-align: center;
}

.quote__amount {
    color: #47bf8d;
    font-size: 2rem;
    font-weight: bold;

    span {
        font-size: 5rem;
    }
}

.quote__amount__info {
    font-size: 1.2rem;
    margin: 0 0 20px 0;
    font-weight: 700;
}

.quote__info {
    font-size: 0.9rem;
    line-height: 1.5rem;
    margin-bottom: 60px;
    text-align: center;
}

.overlay {
    background-color: rgba(255, 255, 255, 0.9);
    left: 0;
    height: 100%;
    position: fixed;
    top: 0;
    width: 100%;
}

.purchase {
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0 20px #ddd;
    box-sizing: border-box;
    left: 50%;
    margin: 0 0 0 -250px;
    padding: 40px;
    position: fixed;
    top: 200px;
    width: 500px;

    h2 {
        margin: 0 0 30px 0;
    }

    .fieldset {
        margin: 0 0 10px 0;
    }

    .cancel {
        color: #999;
        cursor: pointer;
        font-size: 0.9rem;
    }
}

.steps {
    display: flex;
    font-size: 12px;
    line-height: 1;
    margin: -50px 0 50px 0;

    strong {
        font-size: 14px;
        display: block;
        line-height: 1.6rem;
    }
}

.steps__item {
    background: #f2f2f2;
    box-sizing: border-box;
    color: #999;
    display: block;
    flex: 1;
    height: 60px;
    padding: 8px 50px 8px 50px;
    position: relative;

    &:first-child {
        padding-left: 25px;
    }
}

.steps__item--active {
    background: $color-primary;
    color: #fff;
}

.steps__item > span:after,
.steps__item > span:before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    position: absolute;
    top: 0;
    left: 0;
    border: solid transparent;
    border-left-color: #f0f0f0;
    border-width: 30px;
}

.steps__item > span:after {
    top: -5px;
    z-index: 1;
    border-left-color: white;
    border-width: 34px;
}

.steps__item > span:before {
    z-index: 2;
}

.steps__item + li > span:before {
    border-left-color: #f2f2f2;
}

.steps__item--active + li > span:before {
    border-left-color: $color-primary;
}

.steps__item:first-child > span:after,
.steps__item:first-child > span:before {
    display: none;
}

.article {
    h1 {
        font-size: 2rem;
        margin: 0 0 25px 0;
    }

    h2 {
        font-size: 1.6rem;
        margin: 0 0 15px 0;
    }

    h3 {
        font-size: 1.4rem;
        margin: 0 0 15px 0;
    }

    p {
        margin: 0 0 25px 0;
    }
}

.article--center {
    text-align: center;
}

.account {
    position: relative;

    .account__status {
        background: #d7f2d7;
        border-radius: 999px;
        font-size: 15px;
        padding: 12px 15px;
        position: absolute;
        right: 0;
        top: -70px;

        img {
            margin: 0 0 0 3px;
            vertical-align: middle;
            width: 24px;
        }
    }

    .account__document {
        font-size: 13px;
        text-align: right;
        margin: 0 0 10px 0;
        padding: 0 10px;
        position: relative;
        top: -5px;
    }
}

.faq {
    font-size: 15px;
    margin: 50px 0 0 0;
}

.StripeElement {
    border: 1px solid #999;
    border-radius: 5px;
    padding: 15px;
}

.hamburger {
    display: none;
    cursor: pointer;
    font-size: 30px;
    position: absolute;
    right: 15px;
    top: 0;
}

.nav {
    display: none;
    list-style-type: none;
    padding: 0;
    position: relative;
    text-align: left;
}

.menu {
    line-height: 40px;
    text-align: left;
}

.menu li a {
    display: block;
    margin: 0;
    padding: 6px 20px;
}

.items {
    display: flex;
    flex-wrap: wrap;

    .item {
        flex: 1;
        margin: 20px 0 40px 0;

        h4 {
            color: $color-primary;
            font-size: 1.2rem;
            margin: 0 0 20px 0;
        }

        img {
            max-width: 80px;
            width: 80%;
        }
    }
}

.documents {
    display: inline-flex;
    flex-direction: column;
    padding: 20px 0;

    .document {
        align-items: center;
        border: 1px solid #999;
        border-radius: 5px;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        margin: 0 0 15px 0;
        max-width: 500px;
        padding: 18px;

        .document_checkmark {
            background-size: contain;
            border: 1px solid #999;
            border-radius: 999px;
            height: 30px;
            width: 30px;
        }

        .document_title {
            padding: 0 15px;
            text-decoration: underline;
        }

        &:hover {
            background-color: #f2f2f2;
        }

        &--checked {
            background-color: #def8de;

            .document_checkmark {
                background: url(./assets/icons/checkmark.svg) center center
                    no-repeat;
            }
        }
    }
}

.modal {
    background-color: rgba(0, 0, 0, 0.5);
    box-sizing: border-box;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    padding: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;

    .modal-content {
        background: #fff;
        border: 1px solid #ccc;
        font-size: 14px;
        max-width: 700px;
        overflow: auto;
        padding: 25px;
        position: relative;
        text-align: left;

        .form {
            max-width: none;
        }
    }

    .modal-close {
        background: url(./assets/icons/close.svg) center center no-repeat;
        cursor: pointer;
        height: 20px;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 20px;
    }
}

.table {
    margin: 0 0 20px 0;
    width: 100%;

    th {
        background-color: #46bf8d;
        border: 1px solid #eee;
        color: #fff;
        font-weight: normal;
        max-width: 200px;
        padding: 6px;
    }

    td {
        border: 1px solid #eee;
        padding: 6px;
    }
}

@media (max-width: 768px) {
    .header__links {
        display: none;
    }

    .hamburger {
        display: block;
    }

    .menu {
        background-color: white;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: block;
        padding: 0 0 20px 0;
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .steps {
        display: none;
    }

    .items {
        .item {
            flex-basis: 50%;
        }
    }

    .purchase {
        left: 0;
        margin: 0;
        padding: 20px;
        top: 70px;
        width: 100%;
    }

    .quote {
        padding-bottom: 50px;
    }
}
